import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Icon, Link, Box, Section } from "@quarkly/widgets";
import { BsPhone, BsEnvelope, BsMap } from "react-icons/bs";
const defaultProps = {
	"padding": "80px 0 90px 0",
	"quarkly-title": "Contacts-5"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"lg-padding": "0px 0px 0px 0px",
			"sm-font": "normal 700 36px/1.2 --fontFamily-sans",
			"margin": "0px 0px 18px 0px",
			"font": "normal 600 42px/1.2 --fontFamily-sans",
			"color": "--primary",
			"text-align": "center",
			"padding": "0px 0 0px 0px",
			"lg-width": "100%",
			"lg-margin": "0px 0px 25px 0px",
			"children": "Skontaktuj się z nami"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"font": "normal 300 18px/1.5 --fontFamily-sansHelvetica",
			"lg-margin": "0px 0 50px 0px",
			"text-align": "center",
			"sm-margin": "0px 0 35px 0px",
			"margin": "16px 0 50px 0px",
			"color": "#60666d",
			"width": "700px",
			"md-width": "auto",
			"children": "Zadzwoń do nas lub wyślij e-mail, aby zarezerwować konsultację już dziś. Nasze drzwi są zawsze otwarte, abyś mógł odkryć swoje kolejne dzieło sztuki!"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "16px 24px",
			"margin": "0px 0px 80px 0px",
			"md-margin": "0px 0px 50px 0px",
			"lg-grid-template-columns": "repeat(2, 1fr)",
			"sm-grid-template-columns": "1fr"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"padding": "36px 20px 20px 20px",
			"border-width": "1px",
			"border-style": "solid",
			"border-color": "--color-lightD2",
			"border-radius": "4px"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsPhone,
			"size": "36px",
			"margin": "0px 0px 20px 0px",
			"color": "--primary"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "--primary",
			"md-margin": "0px 0px 20px 0px",
			"children": "Telefon"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "tel:+48 554 128 013",
			"color": "#47474d",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/1.5 --fontFamily-sansHelvetica",
			"display": "block",
			"text-align": "center",
			"margin": "0px 0px 15px 0px",
			"children": "+48 554 128 013"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"padding": "36px 20px 20px 20px",
			"border-width": "1px",
			"border-style": "solid",
			"border-color": "--color-lightD2",
			"border-radius": "4px"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsEnvelope,
			"size": "36px",
			"margin": "0px 0px 20px 0px",
			"color": "--primary"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "--primary",
			"md-margin": "0px 0px 20px 0px",
			"children": "Email"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "mailto:info@xovirel.com",
			"color": "#47474d",
			"text-decoration-line": "initial",
			"font": "normal 300 18px/1.5 --fontFamily-sansHelvetica",
			"display": "block",
			"margin": "0px 0px 15px 0px",
			"text-align": "center",
			"children": "info@xovirel.com"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"padding": "36px 20px 20px 20px",
			"border-width": "1px",
			"border-style": "solid",
			"border-color": "--color-lightD2",
			"border-radius": "4px"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "bs",
			"icon": BsMap,
			"size": "36px",
			"margin": "0px 0px 20px 0px",
			"color": "--primary"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"font": "normal 500 24px/1.2 --fontFamily-sansHelvetica",
			"text-align": "center",
			"color": "--primary",
			"md-margin": "0px 0px 20px 0px",
			"children": "Adres"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"color": "#47474d",
			"font": "normal 300 18px/1.5 --fontFamily-sansHelvetica",
			"text-align": "center",
			"border-color": "#b8acac",
			"md-margin": "0px 0px 15px 0px",
			"children": "Okrąglak, 15, Kazimierza Pużaka 1, Warszawa"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"md-justify-content": "center",
			"md-align-items": "center",
			"justify-content": "center",
			"align-items": "center",
			"margin": "0px 0px 50px 0px",
			"flex-wrap": "wrap",
			"sm-margin": "0px 0px 36px 0px"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"color": "--darkL2",
			"padding": "6px 12px 6px 12px",
			"margin": "0px 6px 0px 0px",
			"md-margin": "0px 6px 0px 6px",
			"href": "/index",
			"font": "normal 500 16px/1.5 --fontFamily-sans",
			"text-decoration-line": "initial",
			"border-color": "--color-darkL2",
			"letter-spacing": "1px",
			"hover-color": "--primary",
			"children": "Principal"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"padding": "6px 12px 6px 12px",
			"margin": "0px 6px 0px 6px",
			"href": "/about",
			"font": "normal 500 16px/1.5 --fontFamily-sans",
			"text-decoration-line": "initial",
			"color": "--darkL2",
			"border-color": "--color-darkL2",
			"letter-spacing": "1px",
			"hover-color": "--primary",
			"children": "À propos"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"padding": "6px 12px 6px 12px",
			"margin": "0px 6px 0px 6px",
			"href": "/contacts",
			"font": "normal 500 16px/1.5 --fontFamily-sans",
			"text-decoration-line": "initial",
			"color": "--darkL2",
			"border-color": "--color-darkL2",
			"letter-spacing": "1px",
			"hover-color": "--primary",
			"children": "Łączność"
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" align-items="center" />
		<Text {...override("text")} />
		<Text {...override("text1")} />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Icon {...override("icon")} />
				<Text {...override("text2")} />
				<Link {...override("link")} />
			</Box>
			<Box {...override("box2")}>
				<Icon {...override("icon1")} />
				<Text {...override("text3")} />
				<Link {...override("link1")} />
			</Box>
			<Box {...override("box3")}>
				<Icon {...override("icon2")} />
				<Text {...override("text4")} />
				<Text {...override("text5")} />
			</Box>
		</Box>
		<Box {...override("box4")}>
			<Link {...override("link2")} />
			<Link {...override("link3")} />
			<Link {...override("link4")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;